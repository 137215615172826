export const boundsMinZoom = 12;
export const maxPolygonsOnMap = 1000;

export const emptyGeojsonFn = () => ({
  type: 'FeatureCollection',
  features: [],
});

export const emptyGeojson = {
  type: 'FeatureCollection',
  features: [],
};

export const clusterTypes = {
  SILOS: 'Silos',
  OUTLETS: 'Outlets',
  NONE: 'None',
};

export const cropColors = {
  Corn: 'rgba(241, 40, 0, 0.7)',
  Soybeans: 'rgba(123, 129, 247, 0.7)',
  Soy: 'rgba(123, 129, 247, 0.7)',
  Pasture: 'rgba(236, 254, 197, 0.7)',
  'Winter cereals': 'rgba(0,159,72,0.7)',
  'Spring cereals': 'rgba(65,255,111,0.7)',
  Sugarbeet: 'rgba(255,112,0,0.7)',
  Sunflower: 'rgba(255,64,255,0.7)',
  Fallow: 'rgba(0,0,0,0.7)',
  'Winter rape': 'rgba(17,50,255,0.7)',
  'Spring rape': 'rgba(2,253,255,0.7)',
  Rapeseed: 'rgba(17,50,255,0.7)',
  Potato: 'rgba(148,23,81,0.7)',
  Rice: 'rgba(255,205,68,0.7)',
  Sugarcane: 'rgba(193,123,160,0.7)',
  Cereals: 'rgba(0,159,72,0.7)',
  Citrus: 'rgba(243,252,68,0.7)',
  Coffee: 'rgba(115,63,93,0.7)',
  Other: 'rgba(224, 224, 224, 0.7)',
  Others: 'rgba(173, 216, 230, 0.7)',
  'Forest Plantation': 'rgba(34, 139, 34, 0.7)',
  'Mosaic of Uses': 'rgba(139, 69, 19, 0.7)',
  // Новые культуры
  Almonds: 'rgba(0, 163, 130, 0.7)', // Миндаль
  Grapes: 'rgba(110, 68, 135, 0.7)', // Виноград
  Pistachios: 'rgba(0, 255, 138, 0.7)', // Фисташки
  Peaches: 'rgba(255, 142, 170, 0.7)', // Персики
  Tomatoes: 'rgba(240, 161, 119, 0.7)', // Помидоры
  Alfalfa: 'rgba(255, 163, 224, 0.7)', // Люцерна
  Walnuts: 'rgba(233, 212, 173, 0.7)', // Грецкие орехи
  Cherries: 'rgba(255, 0, 255, 0.7)', // Вишня
  Prunes: 'rgba(255, 142, 170, 0.7)', // Чернослив
  Pomegranates: 'rgba(175, 154, 110, 0.7)', // Гранат
  Garlic: 'rgba(255, 102, 102, 0.7)', // Чеснок
  Plums: 'rgba(255, 142, 170, 0.7)', // Сливы
  Triticale: 'rgba(212, 155, 186, 0.7)', // Тритикале
  Apricots: 'rgba(255, 142, 170, 0.7)', // Абрикосы
  Olives: 'rgba(51, 71, 51, 0.7)', // Оливки
  Onions: 'rgba(255, 204, 102, 0.7)', // Лук
  Oranges: 'rgba(226, 110, 36, 0.7)', // Апельсины
  Apples: 'rgba(184, 0, 79, 0.7)', // Яблоки
};

export const styles = {
  popup: {
    backgroundColor: '#1b1b1b',
    padding: 0,
    margin: 0,
    color: 'white',
    maxWidth: 232,
  },
};
