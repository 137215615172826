import React, { memo, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useGetFarmByRegionAndIdQuery } from 'store/slices/api/farms';
import MapSpinner from 'views/Map/components/Map/MapSpinner';
import { PieChart, Pie, Cell } from 'recharts';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { cropColors } from 'views/Map/components/Map/constants';
import { convertMeters } from 'utils/convertMeters';

const OwnerInfo = ({ toggleFields, isShowAdditionalFields }) => {
  const params = useParams();
  const { t } = useTranslation();
  const { isLoading, data } = useGetFarmByRegionAndIdQuery({
    regionId: params.id,
    farmId: params.owner,
  });

  const ownerInfo = useMemo(() => data || {}, [data]);

  const pieData = useMemo(
    () =>
      (data?.crops_stats || [])
        .slice(0, 10)
        .map((item) => ({
          name: item.crop_specific || item.crop_class,
          baseName: item.crop_specific || item.crop_class,
          value: item.fields_area,
        }))
        .concat(
          data?.crops_stats?.length > 10
            ? (data?.crops_stats || []).slice(10).reduce(
                (res, item) => {
                  res.value += item.fields_area || 0;
                  return res;
                },
                {
                  name: 'Others',
                  baseName: 'Others',
                  value: 0,
                }
              )
            : []
        ),
    [data]
  );

  if (isLoading) {
    return <MapSpinner />;
  }

  if (!ownerInfo) {
    return <Navigate to={`/farms/${params.id}`} />;
  }

  return (
    <div>
      <h1 className='farm-item__header'>
        {ownerInfo.name} {ownerInfo.tax_id ? `(${ownerInfo.tax_id})` : ''}
      </h1>
      <div className='farm-item__field-info-wrap'>
        <div className='farm-item__field-info'>
          <div>
            {convertMeters(ownerInfo.fields_area)} {t('ha')}
          </div>
          <div>{t('farmTableColumns.totalCropland')}</div>
        </div>
        <div className='farm-item__field-info '>
          <div>{ownerInfo.fields_count}</div>
          <div>{t('farmItem.fields')}</div>
        </div>
        <div className='farm-item__field-info '>
          <div>
            {Math.floor(convertMeters(ownerInfo.fields_area) / ownerInfo.fields_count)} {t('ha')}
          </div>
          <div>{t('farmItem.averageFieldSize')}</div>
        </div>
      </div>
      <div className='farm-item__pie'>
        <PieChart width={300} height={300}>
          <Pie
            data={pieData}
            dataKey='value'
            nameKey='name'
            cx='50%'
            cy='50%'
            outerRadius={100}
            fill='#8884d8'
          >
            {pieData.map((entry) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={cropColors[entry.baseName]?.replace('0.7', '1') || 'rgba(224, 224, 224, 1)'}
              />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className='farm-item__pie-info-wrap'>
        {pieData.map((entry) => (
          <div className='farm-item__pie-info-wrap' key={entry.name}>
            <div
              className='farm-item__pie-info-color'
              style={{
                background: cropColors[entry.baseName] || 'rgba(224, 224, 224, 0.7)',
              }}
            />
            <div className='farm-item__pie-info-text'>
              {t(`farmTableColumns.${entry.name}`, entry.name)}: {convertMeters(entry.value)}{' '}
              {t('ha')}
            </div>
          </div>
        ))}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{ marginTop: '24px', cursor: 'pointer' }}
          className={classNames('copy-link')}
          tabIndex={0}
          role='button'
          onClick={toggleFields}
        >
          {isShowAdditionalFields
            ? t('farmItem.hideAdditionalFilters')
            : t('farmItem.showAdditionalFilters')}
        </div>
      </div>
    </div>
  );
};

export default memo(OwnerInfo);
